:root {
	--bg-first: rgba(28, 40, 69, 1);
	--bg-second: rgba(20, 50, 119, 0.85);
	--text-gray: rgba(137, 149, 163, 1);
	--text-purple: rgba(120, 139, 186, 1);
	--text-blue: rgba(41, 60, 102, 1);
	--white: rgba(255, 255, 255, 1);
	--green: rgba(121, 168, 61, 1);
	--orange: #ff6f00;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

*::after::before {
	box-sizing: border-box;
}

body {
	font-size: 62.5%;
	font-family: "Baloo 2";
	background-color: var(--bg-second);
}

/* Titles of sections */
h3 {
	font-size: 2.2rem;
	font-weight: 800;
	color: var(--white);
}

h2 {
	font-size: 3rem;
	font-weight: 800;
	color: var(--white);
	line-height: 50px;
}

h1 {
	font-size: 3.5rem;
	font-weight: 800;
	color: var(--white);
}

p {
	color: var(--text-purple);
	font-weight: 500;
}

.btn {
	border: none;
	outline: none;
}

.btn:hover {
	background-color: var(--orange);
}

.btn:active,
.btn:focus {
	background-color: var(--green);
	outline: none;
	border: none;
	box-shadow: none;
}

@media (max-width: 992px) {
	h1,
	h2,
	h3 {
		font-size: 2rem;
		line-height: 40px;
	}
}
