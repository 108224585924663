.testimony {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 100px 0;
}

.testimony h2 {
	max-width: 40%;
	text-align: center;
}

/* .opinions {
	display: flex;
	gap: 15px;
	margin: 100px 0;
} */

.opinion-slide {
	min-width: 100%;
	/* margin: 50px 0; */
	padding: 0px;
}

.card-wrapper {
	display: flex;
	justify-content: center;
}

.opinion-card {
	/* background-color: darkblue; */
	position: relative;
	/* max-width: 300px; */
	min-width: 500;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 80px 5px;
	padding: 20px;
	border: solid 2px var(--white);
	border-radius: 25px;
	color: var(--white);
	gap: 15px;
}

.opinion-card .user-avatar {
	/* position: absolute;
	top: -20%; */
	width: 70px;
	height: 70px;
	border-radius: 50%;
	object-fit: cover;
	object-position: center;
}

.opinion-card .user-info {
	display: flex;
	flex-direction: column;
	gap: 2px;
	text-align: center;
}

.opinion-card .user-name {
	font-size: 1.2rem;
	font-weight: 700;
	line-height: 20px;
}

.opinion-card .user-rol {
	font-size: 1rem;
	font-weight: 400;
	line-height: 16px;
}

.opinion-card .user-opinion {
	text-align: center;
	color: var(--white);
	font-size: 0.8rem;
	font-weight: 400;
}

.opinion-card .stars {
	max-width: 50%;
}

.opinion-swiper {
	/* background-color: firebrick; */
	padding: 50px;
	/* max-width: 600px; */
}

@media (max-width: 992px) {
	.opinions {
		max-width: 90%;
		display: inline-block;
	}

	.testimony h2 {
		max-width: 90%;
		text-align: center;
	}
}
