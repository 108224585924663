.ranking-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 80px 0;
	background-color: var(--bg-first);
	color: var(--white);
	gap: 30px;
}

.ranking-section .text {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 50vw;
	text-align: center;
	font-size: 1.4rem;
	gap: 20px;
}

/* .text h2 {
	max-width: 70%;
	font-size: 2.7rem;
	font-weight: 800;
} */

.ranking {
	max-width: 100%;
}

.ranking_card {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 80px;
	padding-bottom: 20px;
	max-width: 300px;
	min-height: 200px;
	border-radius: 25px;
	color: white;
	background-color: var(--bg-second);
	margin: 80px auto;
}

.user_info {
	position: absolute;
	min-width: 100%;
	top: -20px;
	display: flex;
	gap: 10px;
	padding: 20px 20px;
	border-radius: 25px;
	/* align-items: center; */
	justify-content: center;
	background-color: var(--white);
}

.user_info img:first-child {
	max-width: 50px;
	max-height: 50px;
}

.user_info .user-photo {
	max-width: 50px;
	max-height: 50px;
	border-radius: 50%;
	object-fit: cover;
}

.user_info .user_place {
	position: absolute;
	max-width: 70px;
	height: 70px;
	top: -30px;
	left: 50%;
	transform: translateX(-50%);
}

.user_info .info_text {
	text-align: left;
	font-size: 0.8rem;
}

.info_text > img {
	max-width: 20px;
	max-height: 20px;
}

.info_text .info_name {
	font-size: 1rem;
	font-weight: 700;
	color: var(--orange);
}

.info_text .info_nickname {
	font-size: 0.7rem;
	font-weight: 500;
	color: var(--text-gray);
}

.info_text .info_flat {
	border-radius: 50%;
}

.items {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 15px;
	padding: 20px 0;
}

.item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	font-size: 1rem;
	padding: 0 30px;
	line-height: 20px;
}

.item > img {
	max-width: 25px;
	max-height: 25px;
}

.item .item-info {
	min-width: 100%;
}

/* Ranking desk */

.ranking-desk {
	display: flex;
	gap: 25px;
}

/* Ranking modal */

.modal {
	background-color: rgba(62, 90, 153, 0.6);
}
.modal-content {
	border-radius: 25px;
}

.close-modal {
	position: absolute;
	max-width: 40px;
	max-height: 40px;
	top: -15px;
	right: -10px;
	padding: 2px;
	background-color: rgba(235, 66, 66, 1);
	border-radius: 50%;
}

.close-modal img {
	max-width: 100%;
	max-height: 100%;
}

.info-user {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 0;
	gap: 20px;
}

.info-user > img {
	width: 90px;
	height: 90px;
	border-radius: 50%;
	object-fit: cover;
	object-position: center;
}

.info-user .info_name {
	font-size: 1.2rem;
	font-weight: 800;
	color: var(--orange);
}

.info-user .info_nickname {
	font-size: 0.8rem;
	font-weight: 500;
	color: var(--text-gray);
}

thead {
	text-align: center;
	color: var(--bg-first);
}

thead img {
	transform: scale(0.8);
}

thead div {
	font-size: 0.8rem;
}

/* Modal body */

.body-modal {
	padding: 0px;
}

tbody {
	color: var(--bg-first);
	text-align: center;
	font-size: 0.8rem;
	font-weight: 600;
}

tbody tr {
	vertical-align: middle;
}

tbody td:first-child {
	padding-left: 30px;
	text-align: left;
}
tbody td:last-child {
	padding-right: 30px;
	text-align: right;
}

@media (max-width: 992px) {
	.ranking-section .text,
	.text h2 {
		max-width: 90%;
	}
}
