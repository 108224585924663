.invitation {
	display: flex;
	justify-content: center;
	min-width: 100%;
	margin: 100px 0;
	padding: 0 50px;
	gap: 100px;
}
.invitation-info {
	max-width: 400px;
	display: flex;
	flex-direction: column;
	gap: 30px;
	padding: 10px;
}

.info-right {
	text-align: right;
}

/* .invitation-info h2 {
	font-size: 3rem;
	font-weight: 700;
	line-height: 50px;
	color: var(--bg-first);
	text-align: start;
} */

.invitation-info div {
	font-size: 1.2rem;
	font-weight: 500;
	color: var(--text-purple);
}

.invitation .invitation-img-md {
	max-width: 300px;
}

.invitation img {
	max-width: 350px;
}

/* Invitation 3 */
.invitation3 {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 70%;
	margin: 0 auto;
	margin-bottom: 100px;
	text-align: center;
	gap: 50px;
}

/* .invitation3 h3 {
	max-width: 60%;
	font-size: 1.5rem;
	font-weight: 800;
	text-align: center;
	padding: 0 20px;
	color: var(--bg-first);
} */

.invitation3 > img {
	max-width: 40%;
}

@media (max-width: 992px) {
	.invitation {
		text-align: center;
		display: inline-block;
		max-width: 30vw;
		padding: 0 20px;
		margin-bottom: 100px;
		/*max-width: 90%; */
		/* margin: 0 auto; */
	}
	.invitation-info {
		max-width: 100%;
	}
	/* .invitation-info h2 {
		font-size: 2rem;
		line-height: 30px;
		text-align: center;
	} */
	.info-right {
		text-align: center;
	}
	.invitation img {
		margin: 30px 0;
	}

	.invitation3 {
		max-width: 90%;
	}

	.invitation3 > img {
		max-width: 100%;
	}

	.invitation .invitation-img-md {
		max-width: 250px;
	}

	.invitation img {
		max-width: 300px;
	}
}
