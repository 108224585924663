/* Intro Section  */

.education-intro {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 100px 0 150px 0;
}

.education-intro .info {
	max-width: 40%;
	font-size: 1.2rem;
	color: var(--white);
}

.education-intro img {
	max-height: 60vh;
	/* background-color: wheat; */
	/* max-width: 30%; */
}
.info b {
	word-spacing: 10px;
}

/* Opinions Sections */

.objectives-section {
	display: flex;
	flex-direction: column;
	background-color: var(--bg-first);
	align-items: center;
	padding-top: 50px;
	gap: 50px;
}

.objectives-section h2 {
	max-width: 45%;
	text-align: center;
}

.objectives-section p {
	max-width: 40%;
	font-size: 1rem;
	text-align: center;
}

.fact-card {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	max-width: 35%;
	margin-top: 30px;
	padding: 10px 30px;
	color: var(--white);
	background-color: #eb4242;
	border-radius: 25px;
	font-size: 1rem;
	gap: 20px;
}

.card-img {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80px;
	width: 80px;
	top: -40px;
	left: 50%;
	transform: translateX(-50%);
	padding: 2px;
	background-color: #eb4242;
	border-radius: 50%;
}

.card-img .photo {
	height: 90%;
	width: 90%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--white);
	padding: 5px;
	border-radius: 50%;
}

.photo img {
	max-width: 90%;
	max-height: 90%;
}

.fact-card .title {
	/* font-size: 2rem; */
	font-weight: 800;
	text-align: left;
	text-transform: uppercase;
}
.fact-card p {
	color: var(--white);
	max-width: 60%;
	text-align: left;
}

.title {
	font-size: 1.2rem;
}

.title .figure {
	font-size: 4rem;
}

.impact {
	/* display: flex; */
	max-width: 30%;
	margin: 50px auto;
	text-align: center;
}

/* Roadmap Section */

.roadmap-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 50px 0;
	gap: 50px;
}

.roadmap-section h2 {
	text-align: center;
}

.features {
	display: flex;
	gap: 30px;
}

.feature-card {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-width: 180px;
	font-size: 0.9rem;
	gap: 10px;
}
.feature-card img {
	max-width: 50%;
}

.feature-card h6 {
	margin-top: 10px;
	font-size: 1.2rem;
	font-weight: 700;
	color: var(--white);
}

@media (max-width: 992px) {
	.education-intro {
		flex-direction: column;
		padding: 50px 0;
		gap: 20px;
	}

	.education-intro .info {
		max-width: 80%;
	}

	.objectives-section h2,
	.objectives-section > p {
		max-width: 90%;
	}

	.fact-card {
		max-width: 70%;
		align-items: flex-start;
		padding: 50px 20px 10px 20px;
	}

	.impact {
		max-width: 80%;
		font-size: 1.5rem;
		line-height: 30px;
	}

	.features {
		flex-direction: column;
	}

	.feature-card {
		max-width: 90%;
		margin: 0 auto;
		align-items: center;
		text-align: center;
		font-size: 1rem;
	}

	.feature-card h6 {
		font-size: 1.5rem;
	}

	.feature-card img {
		max-width: 30%;
	}

	/* .roadmap-section h2 {
		max-width: 90%;
	} */
}

@media (max-width: 560px) {
	.fact-card {
		max-width: 90%;
	}
}
