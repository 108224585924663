.footer {
	display: flex;
	justify-content: space-between;
	align-items: top;
	padding: 50px;
	color: var(--white);
	background-color: var(--bg-first);
}

.footer .logo-wrapper {
	max-width: 120px;
}

.logo-wrapper img {
	max-width: 100%;
}

.footer ul {
	padding: 0;
	list-style: none;
	font-size: 1.2rem;
	line-height: 40px;
}

.footer-links a,
.footer-contact a {
	text-decoration: none;
	color: var(--white);
}

.footer-social-media {
	display: flex;
	flex-direction: column;
	align-items: left;
}
.footer-social-media ul {
	display: flex;
	justify-content: start;
	margin-top: 50px;
	gap: 15px;
}

.stores img {
	max-width: 200px;
}

@media (max-width: 992px) {
	.footer {
		max-width: 100%;
		align-items: center;
		flex-direction: column;
		text-align: center;
		gap: 50px;
	}

	.footer-social-media {
		align-items: center;
	}

	.stores {
		display: flex;
	}

	.stores img {
		max-width: 170px;
		padding: 0;
	}
}
