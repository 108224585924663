.market_section {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	margin-top: 300px;
	margin-bottom: 200px;
	gap: 50px;
}

.market_section .info {
	max-width: 30%;
}

/* .market_section h2 {
	font-size: 3rem;
	font-weight: 800;
	line-height: 50px;
	color: var(--bg-first);
} */

.market_section > div {
	font-size: 1.2rem;
	font-weight: 400;
	color: var(--text-gray);
}

.market_section > img {
	min-width: 300px;
}

.partners {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.partners .types {
	display: flex;
	justify-content: center;
	padding: 0;
	list-style: none;
	gap: 50px;
}

.partners .market-type {
	display: inline-block;
	font-size: 1.2rem;
	color: var(--white);
	cursor: pointer;
	text-decoration: none;
}

.partners .activeType {
	font-weight: 800;
	border-bottom: solid 3px var(--green);
}

.partners .logos {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 0;
	list-style: none;
	gap: 10px;
}

.logos .wrapper-logo {
	max-width: 100px;
	width: 160px;
	padding: 0 5px;
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 5px;
	border-radius: 5px;
	background-color: #fcfcfc;
}

.logos .wrapper-logo img {
	max-width: 100%;
}

@media (max-width: 992px) {
	.market_section {
		display: inline-block;
		text-align: center;
		margin-top: 100px;
	}

	.market_section .info {
		max-width: 90%;
		margin: 0px auto;
		padding-bottom: 30px;
	}
	.market_section > img {
		min-width: 200px;
	}

	.partners ul {
		justify-content: center;
	}
}
