.form-section {
	display: flex;
	flex-direction: column;
}

.form-section > img {
	max-width: 100vw;
	padding: 0 50px;
}

.contact-form {
	display: flex;
	justify-content: center;
	padding: 100px 0;
	gap: 50px;
	/* background-color: var(--bg-first); */
}

.contact-form form {
	display: flex;
	flex-direction: column;
	max-width: 40%;
	padding-right: 20px;
	font-size: 1.2rem;
	color: var(--white);
	gap: 30px;
}

/* form h2 {
	font-size: 2.5rem;
	font-weight: 800;
} */

.fields {
	max-width: 70%;
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.fields input {
	border: none;
	border-bottom: 2px solid var(--text-purple);
	-webkit-appearance: none;
	-ms-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: none;
	outline: none;
	padding: 5px 0;
	color: var(--white);
	font-weight: 500;
}

.fields input::placeholder {
	color: var(--white);
	opacity: 0.5;
	font-weight: 500;
}

.contact-form > img {
	min-height: 500px;
	/* min-width: 200px; */
}

@media (max-width: 992px) {
	.contact-form {
		display: inline-block;
		padding: 50px 0;
	}

	.contact-form form {
		max-width: 90%;
		margin: 0 auto;
		text-align: center;
	}

	.contact-form > img {
		display: flex;
		min-height: 300px;
		margin: 0 auto;
	}

	form .fields {
		max-width: 100%;
	}
}
