.nav {
	min-width: 100%;
	padding: 10px 30px;
	/* background-color: var(--bg-second); */
	color: var(--white);
}

.nav-brand img {
	max-width: 100px;
}

.nav-main {
	display: flex;
	flex: 3;
	justify-content: flex-end;
	align-items: center;
	padding: 0 50px;
	gap: 40px;
}

.nav .nav-social-media {
	display: flex;
	list-style: none;
	margin: auto 0;
	gap: 10px;
}

.nav-social-media li img {
	max-width: 25px;
}

.nav-item {
	font-size: 1.2rem;
	color: var(--white);
	text-decoration: none;
}

.nav-item:hover {
	color: var(--white);
	opacity: 0.5;
}

.nav-item-active {
	font-weight: 600;
	border-bottom: solid 3px var(--green);
}

.nav-item-active:hover {
	opacity: 1;
}

.helper-button {
	position: fixed;
	bottom: 20px;
	right: 40px;
	padding: 10px 20px;
	border-radius: 25px;
	border: none;
	box-shadow: 0px 5px 5px rgb(95, 95, 95);
	background-color: white;
	color: var(--green);
	text-transform: uppercase;
	font-weight: 700;
	z-index: 100;
}

/* Mobile Nav */

.burger-icon {
	display: none;
}

.sidebar-items ul {
	display: flex;
	flex-direction: column;
	padding: 0;
	list-style: none;
	font-size: 1.2rem;
	gap: 10px;
}

.sidebar-items {
	display: block;
	padding: 20px 0;
	color: red;
	text-align: left;
	text-decoration: none;
}
.sidebar-items .nav-social-media {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 50px;
	gap: 20px;
	filter: invert(1);
}

@media (max-width: 992px) {
	.nav {
		margin: 20px 0;
		padding: 0 10px;
	}
	.nav-item {
		font-size: 1.2rem;
		color: var(--bg-second);
		text-decoration: none;
		padding: 0 20px;
		margin: 40px 0;
	}
	.nav-item-active {
		font-weight: 700;
		border-bottom: none;
		color: var(--green) !important;
	}
	.nav-social-media-mobile {
		display: flex;
		flex-direction: row !important;
		padding: 0 20px !important;
		justify-content: left;
	}

	.nav-social-media-mobile img {
		max-width: 20px;
	}

	.navbar-collapse {
		display: none;
	}

	.nav-brand {
		display: none;
	}

	.burger-icon {
		display: block;
		max-width: 50px;
		max-height: 50px;
		background: transparent;
		/* border: none;
		outline: none; */
	}

	.burger-icon img {
		max-width: 100%;
	}

	.burger-icon:hover,
	.burger-icon:active,
	.burger-icon:focus {
		background: transparent !important;
		/* border: none;
		outline: none;
		box-shadow: none; */
	}

	.play-btn {
		display: block;
		color: var(--white);
	}

	/* sidebar */
	.nav-logo img {
		max-width: 90px;
	}

	/* helper button */

	.helper-mobile {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}

	.helper-mobile:active,
	.helper-mobile:focus {
		background-color: var(--white) !important;
	}

	.helper-mobile img {
		max-width: 200%;
	}
}
