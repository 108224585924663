.store-btn {
	display: flex;
	/* justify-content: center; */
	align-items: center;
	/* padding: 10px 20px; */
	border-radius: 15px;
	border: none;
	background-color: black;
	gap: 10px;
	transition: 0.5s;
	box-shadow: 5px 3px 15px 5px rgba(0, 0, 0, 0.4);
}

.store-btn:hover {
	background-color: black;
	transform: translateY(-5px);
}

.store-btn img {
	height: 35px;
	width: 35px;
}

.store-btn .store-info {
	font-family: Arial, Helvetica, sans-serif;
	text-align: left;
}

.store-btn .title {
	font-size: 0.5rem;
	text-transform: uppercase;
}

.store-btn .subtitle {
	font-size: 1.5rem;
}
