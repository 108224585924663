.main-section {
	background-image: url("https://alfi-images.s3.us-east-2.amazonaws.com/LandingBackground.png");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.bg-color {
	display: flex;
	justify-content: center;
	padding: 30px 0;
	background-color: var(--bg-second);
	overflow: hidden;
	min-height: 10vh;
}

.main-row {
	/* min-height: 80vh; */
	max-width: 80%;
	color: var(--white);
}

/* .avatar {
	height: 100%;
	display: flex;
	justify-content: start;
	align-items: flex-end;
}

.avatar-img {
	max-width: 80%;
} */

.info {
	/* max-width: 500px; */
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	height: 100%;
	text-align: left;
	gap: 20px;
}

.info-content {
	display: flex;
	flex-direction: column;
	gap: 30px;
}
/* .info-content h1 {
	font-size: 3.5rem;
	font-weight: 800;
} */

.info-content p {
	max-width: 60%;
	font-size: 1.2rem;
}

.preview {
	display: flex;
	align-items: flex-start;
	justify-content: left;
	/* text-align: center; */
}

.preview img {
	max-width: 100%;
}

.info-stores {
	display: flex;
	gap: 40px;
}

.info-stores a {
	display: flex;
	width: 150px;
	height: 50px;
	align-items: flex-end;
}

.info-stores a:hover {
	cursor: pointer;
}

.info-stores img {
	max-width: 100%;
	max-height: 100%;
}

@media (max-width: 992px) {
	/* .main-section {
		padding: 30px 10px;
	} */

	.main-row {
		max-width: 100%;
	}

	/* .avatar {
		display: none;
	} */

	.info {
		text-align: center;
		gap: 50px;
	}

	.info-content h1 {
		font-size: 2.8rem;
		font-weight: 700;
	}

	.info-content p {
		min-width: 100%;
		text-align: center;
	}
	.info-stores {
		margin: 0 auto;
	}
	.preview {
		margin-top: 20px;
	}

	.preview img {
		max-width: 100%;
	}

	.stores .store-img {
		max-width: 50%;
	}
}
