.noback-content {
	position: relative;
	display: flex;
	background: none;
	justify-content: center;
	align-items: center;
	border: none;
}

.thanks-message {
	position: absolute;
	top: 50%;
	left: 20%;
	max-width: 250px;
	transform: translateX(-50%), translateY(-50%);
	text-align: center;
	font-size: 1.2rem;
	font-weight: 500;
	color: var(--text-purple);
}

.thanks-message h6 {
	color: var(--green);
	font-size: 2rem;
	font-weight: 800;
	text-transform: uppercase;
}
