.marketplace-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px 0;
	gap: 50px;
}

/* .marketplace-section h1 {
	font-size: 3rem;
	font-weight: 700;
	color: var(--bg-first);
} */

.marketplace-section img {
	max-width: 40%;
}

@media (max-width: 992px) {
	.marketplace-section h1 {
		max-width: 90%;
		text-align: center;
	}
	.marketplace-section img {
		max-width: 90%;
	}
}
